import baseComponent from "@/scripts/baseComponent";
import { mapState } from "vuex";
import EventBus from "@/event/EventBus";
import { API_CALL_STATUS } from "@/constant/config";
import CaretTop from "@/views/components/Icon/CaretTop.vue";
import CaretBottom from "@/views/components/Icon/CaretBottom.vue";
import softwareLicenseApi from '@/api/common/softwareLicense';
import { vmMachineStatusText, vmMachineStatusColor }
    from "@/constant/vmMachineStatusConst";
    import moment from 'moment'
 
export default {

    components: {
        CaretTop,
        CaretBottom,
    },

    extends: baseComponent,

    data() {
        return {
            isCheckAll: false,
            licenseSoftwareCat: [
                { value: 'OctaneRender', label: 'Otoy Octane Render' },
                { value: 'RedshiftRender', label: 'Maxon Redshift Render' },
                { value: 'Cinema4DRender', label: 'Maxon Cinema 4D' },
                { value: 'Cinema4DRender-RedshiftRender', label: 'Maxon Cinema 4D (Redshift Bundle)' },
                { value: 'Cinema4DIncludeRedshiftRender', label: 'Maxon Cinema 4D (Redshift Include)' },
                { value: 'Cinema4DRLM', label: 'Maxon Cinema 4D RLM' },
                { value: 'RedshiftRLM', label: 'Maxon Redshift RLM' }
            ],
            licenseStatus: [
                { value: 0, label: 'Expired' },
                { value: 1, label: 'Ready' }
            ],
            listData: [],
            //statistics: null,
            isLoading: false,
            vmMachineStatusText: vmMachineStatusText,
            vmMachineStatusColor: vmMachineStatusColor,
            pagination: {
                status: 1,
                querySearch: '',
                includeDeleted: false,
                includeExpired: false,
                orderBy: 'A.ID',
                directionSort: 'DESC',
                pageIndex: 1,
                pageSize: 50,
                outRowsNumber: 0
            },
            dialogInsertUpdateVisible: false,
            dialogInsertUpdateTitle: '',
            insertUpdateModel: {
                id: '',
                licenseName: '',
                expiredDate: null
            },
            intervalLoadStatistics: null,
            isShowSummaryMobile: false,
            toogleFilterMobile: false
        }
    },
    watch: {
        toogleFilterMobile(val) {
            if (val) $('body').addClass('has-filter-enable')
            else $('body').removeClass('has-filter-enable')
        }
    },
    computed: {
        ...mapState({
            userInfo: state => state.auth.user,
            rolesInfo: state => state.auth.roles,
            listStaff: state => state.staffs,
            decentralization: state => state.auth.decentralization,
            statistics: state => state.license.statistics,
        }),
        isValidLicenseName() {
            //const re = /^([0-9a-zA-Z_\s\d]+)_([\d]+)_([\d]+)$/;
            //return (this.insertUpdateModel.licenseName !== null && this.insertUpdateModel.licenseName !== ''
            //    && this.insertUpdateModel.id !== null && this.insertUpdateModel.id !== ''            );
            return true;
        }
    },

    created() {
        EventBus.$on("openPopup", this.openPopup);
        EventBus.$on("closePopup", this.closePopup);
        this.getData();
        //this.getStatistics();
        this.$store.dispatch("license/getStatistics");
    },

    mounted() {
        // this.intervalLoadStatistics = setInterval(() => {
        //     this.getStatistics();
        // }, 10000);
        window.addEventListener('resize', this.onResize)
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize)
        if(this.intervalLoadStatistics) clearInterval(this.intervalLoadStatistics)
    },
    methods: {
        toogleFilterPanelMobile(){
            this.toogleFilterMobile = !this.toogleFilterMobile
        },
        onResize() {
            this.toogleFilterMobile = false
        },
        makeid(length) {
            var result = '';
            var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for (var i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() *
                    charactersLength));
            }
            return result;
        },
        getData() {
            this.isLoading = true;
            this.editAction = "insert";
            softwareLicenseApi
                .getAllLicenseView({
                    query: this.pagination.querySearch,
                    includeDeleted: this.pagination.includeDeleted,
                    includeExpired: this.pagination.includeExpired,
                    pageIndex: this.pagination.pageIndex,
                    pageSize: this.pagination.pageSize,
                    orderBy: this.pagination.orderBy,
                    directionSort: this.pagination.directionSort
                })
                .then((res) => {
                    try {
                        if (res.data.result === 0) {
                            this.$set(this, 'listData', res.data.data.data)
                            this.$set(this, 'pagination', {
                                status: 1,
                                querySearch: this.pagination.querySearch,
                                includeDeleted: this.pagination.includeDeleted,
                                includeExpired: this.pagination.includeExpired,
                                orderBy: res.data.data.pagingItem.orderBy,
                                directionSort: res.data.data.pagingItem.directionSort,
                                pageIndex: res.data.data.pagingItem.pageIndex,
                                pageSize: res.data.data.pagingItem.pageSize,
                                outRowsNumber: res.data.data.pagingItem.outRowsNumber
                            })
                            this.feedStatusLoading(API_CALL_STATUS.SUCCESS, this.listData)
                        }
                    } catch (error) {
                        console.log('getAllLicenseView -> error', error)
                        this.feedStatusLoading(API_CALL_STATUS.FAILED)
                    }
                    this.isLoading = false
                })
                .catch((error) => {
                    console.log('getAllLicenseView -> error', error)
                    this.feedStatusLoading(API_CALL_STATUS.FAILED)
                    this.isLoading = false
                })
        },
        // getStatistics() {
        //     softwareLicenseApi.getLicenseStatistics().then((res) => {
        //         try {
        //             if (res.data.result === 0) {
        //                 this.$set(this, 'statistics', res.data.data);
        //             }
        //         } catch (error) {
        //             console.log("getLicenseStatistics -> error", error);
        //         }
        //     }).catch(error => {
        //         console.log("getLicenseStatistics -> error", error);
        //     });
        // },
        // Selected all item
        selectedAll() {
            this.listData.forEach(element => {
                element.isChecked = this.isCheckAll;
            });
        },

        // Selected item
        selectedItem() {
            if (this.listData.filter(x => x.isChecked).length === this.listData.length) {
                this.isCheckAll = true;
            } else {
                this.isCheckAll = false;
            }
        },
        // Sort list
        sortList(element) {
            this.pagination.pageIndex = 1;
            this.pagination.orderBy = element;
            if (this.pagination.directionSort === 'ASC') {
                this.pagination.directionSort = 'DESC'
            } else {
                this.pagination.directionSort = 'ASC'
            }
            this.getData();
        },
        // Pagination size change
        handleSizeChange(pageSize) {
            this.pagination.pageIndex = 1;
            this.pagination.pageSize = pageSize;
            this.getData();
        },

        // Pagination page change
        handleCurrentChange(element) {
            this.pagination.pageIndex = element;
            this.getData();
        },
        // Action
        performAction(param) {
            switch (param.action) {
                case 'refresh':
                    this.getData(this.pagination)
                    break
                case 'copyToClipboard':
                    this.copyToClipboard(param.data.inputId, param.data.data)
                    break
                case 'add':
                    this.editAction = 'insert'
                    this.insertUpdateModel = {
                        id: null,
                        licenseName: '',
                        status: 1,
                        softwareName: 'OctaneRender',
                        licenseMetaData: '',
                        usernameLic: '',
                        passwordLic: '',
                        expiredDate: null,
                        isKeyFloat: false,
                        isBundle: false,
                        bundleWithId: null
                    }
                    this.dialogInsertUpdateTitle = 'Add license'
                    this.dialogInsertUpdateVisible = true
                    break
                case 'copy':
                    {
                        this.editAction = 'insert'
                        const localExpired = moment(param.data.expiredDate).add(7, 'hours').toDate()
                        this.insertUpdateModel = {
                            id: null,
                            licenseName: `${param.data.licenseName} copy`,
                            status: param.data.status,
                            softwareName: param.data.softwareName,
                            licenseMetaData: param.data.licenseMetaData,
                            usernameLic: param.data.usernameLic,
                            passwordLic: param.data.passwordLic,
                            expiredDate: localExpired,
                            isKeyFloat: param.data.isKeyFloat,
                            isBundle: param.data.isBundle,
                            bundleWithId: null
                        }
                        this.dialogInsertUpdateTitle = 'Add license'
                        this.dialogInsertUpdateVisible = true
                    }
                    break
                case 'modify':
                    {
                        this.editAction = 'modify'
                        const localExpired = moment(param.data.expiredDate).add(7, 'hours').toDate()
                        this.insertUpdateModel = {
                            id: param.data.id,
                            licenseName: param.data.licenseName,
                            status: param.data.status,
                            softwareName: param.data.softwareName,
                            licenseMetaData: param.data.licenseMetaData,
                            usernameLic: param.data.usernameLic,
                            passwordLic: param.data.passwordLic,
                            expiredDate: localExpired,
                            isKeyFloat: param.data.isKeyFloat,
                            isBundle: param.data.isBundle,
                            bundleWithId: param.data.bundleWithId
                        }
                        this.dialogInsertUpdateTitle = 'Modify license'
                        this.dialogInsertUpdateVisible = true
                    }
                    break
                case 'clearUse':
                    this.clearUse(param.data.id)
                    break
                case 'setActiveLicense':
                    this.setActiveLicense(param.data.id)
                    break
                case 'delete':
                    this.deleteItem(param.data.id)
                    break
                case 'restore':
                    this.restoreItem(param.data.id)
                    break
                case 'renew-1-month':
                    break
            }
        },
        // Search list
        searchList() {
            this.pagination.status = 0;
            if (this.pagination.querySearch) {
                this.pagination.querySearch = this.pagination.querySearch.substring(this.pagination.querySearch.lastIndexOf("@") + 1);
            }
            this.getData();
        },
        saveItemChanged() {
            let expiredDateUtc = null;
            let requestUpdate = {...this.insertUpdateModel}
            if(typeof requestUpdate.expiredDate !== 'undefined' && requestUpdate.expiredDate !== null){ 
                let dateFormated = moment(requestUpdate.expiredDate).add(-7, 'hours').format('YYYY-MM-DD HH:mm:ss');
                expiredDateUtc = moment.utc(dateFormated, 'YYYY-MM-DD HH:mm:ss');
                requestUpdate.expiredDateUtc = expiredDateUtc;
            }
            console.log("requestUpdate", requestUpdate);
            softwareLicenseApi.insertOrUpdateLicense(requestUpdate).then((res) => {
                try {
                    if (res.data.result === 0) {
                        if (this.insertUpdateModel.id === null) {
                            this.getData();
                            this.$message({
                                message: 'Congrats, add license success.',
                                type: 'success'
                            });
                        }
                        else {
                            let indexItemUpdate = this.listData.findIndex(x => x.id === this.insertUpdateModel.id);
                            if (indexItemUpdate !== -1) {
                                this.getData();
                                this.$message({
                                    message: 'Congrats, update license success.',
                                    type: 'success'
                                });
                            }
                        }
                        this.dialogInsertUpdateVisible = false;
                    }
                } catch (error) {
                    console.log("insertOrUpdateLicense -> error", error);
                }
            }).catch(error => {
                console.log("insertOrUpdateLicense -> error", error);
            });
        },
        clearUse(itemId) {
            this.$confirm('This will permanently clean the license data. Continue?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }).then(() => {
                softwareLicenseApi.clearUseLicense(itemId).then((res) => {
                    try {
                        if (res.data.result === 0) {
                            let indexItemUpdate = this.listData.findIndex(x => x.id === itemId);
                            if (indexItemUpdate !== -1) {
                                this.$set(this, this.listData[indexItemUpdate], res.data.data);
                            }
                            this.$message({
                                message: 'Congrats, update license success.',
                                type: 'success'
                            });
                        }
                        else {
                            this.$message({
                                message: 'An error while update license. Please try again or contact Administrators',
                                type: 'danger'
                            });
                        }
                    } catch (error) {
                        console.log("clearUse -> error", error);
                    }
                }).catch(error => {
                    console.log("clearUse -> error", error);
                });
            }).catch(() => { });
            
        },
        setActiveLicense(itemId) {
            this.$prompt('Please input machine ID', 'Input machine ID actived', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                inputPattern: /([0-9]+)/,
                inputErrorMessage: 'Invalid Machine ID'
            }).then(({ value }) => {
                this.$confirm('This will permanently set the license data. Continue?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    softwareLicenseApi.setActiveLicense(itemId, value).then((res) => {
                        try {
                            if (res.data.result === 0) {
                                let indexItemUpdate = this.listData.findIndex(x => x.id === itemId);
                                if (indexItemUpdate !== -1) {
                                    this.$set(this, this.listData[indexItemUpdate], res.data.data);
                                }
                                this.$message({
                                    message: 'Congrats, update license success.',
                                    type: 'success'
                                });
                            }
                            else {
                                this.$message({
                                    message: 'An error while update license. Please try again or contact Administrators',
                                    type: 'danger'
                                });
                            }
                        } catch (error) {
                            console.log("setActiveLicense -> error", error);
                        }
                    }).catch(error => {
                        console.log("setActiveLicense -> error", error);
                    });
                }).catch(() => { });

            }).catch(() => { });
            
        },
        deleteItem(itemId) {
            this.$confirm('This will permanently delete the license. Continue?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }).then(() => {
                softwareLicenseApi.deleteLicense(itemId).then((res) => {
                    try {
                        if (res.data.result === 0) {

                            this.$message({
                                type: 'success',
                                message: 'Delete completed'
                            });

                            let indexItemDelete = this.listData.findIndex(x => x.id === itemId);
                            if (indexItemDelete !== -1) {
                                this.listData.splice(indexItemDelete, 1);
                            }
                        }
                        else {
                            this.$message({
                                message: 'An error while delete license. Please try again or contact Administrators',
                                type: 'danger'
                            });
                        }
                    } catch (error) {
                        console.log("deleteItem -> error", error);
                    }
                }).catch(error => {
                    console.log("deleteItem -> error", error);
                });

            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: 'Delete canceled'
                });
            });
        },
        restoreItem(itemId){
            this.$confirm('This will permanently undelete the license. Continue?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }).then(() => {
                softwareLicenseApi.restoreLicense(itemId).then((res) => {
                    try {
                        if (res.data.result === 0) {

                            this.$message({
                                type: 'success',
                                message: 'Restore completed'
                            });

                            let indexItemDelete = this.listData.findIndex(x => x.id === itemId);
                            if (indexItemDelete !== -1) {
                                this.listData.splice(indexItemDelete, 1);
                            }
                        }
                        else {
                            this.$message({
                                message: 'An error while Restore license. Please try again or contact Administrators',
                                type: 'danger'
                            });
                        }
                    } catch (error) {
                        console.log("restoreItem -> error", error);
                    }
                }).catch(error => {
                    console.log("restoreItem -> error", error);
                });

            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: 'Restore canceled'
                });
            });
        },
    }
}